// @file Shred types for the Wall collections store.

import type { FolderId, UserGroupId } from '@@/types'

// TODO: turn combined_recents and combined_shared to recents and shared when we deprecare old recents and old shared
export type CrossLibraryCollectionFilter =
  | 'favorites'
  | 'combined_recents'
  | 'combined_shared'
  | 'gallery'
  | 'writable_favorites'
  | 'writable_combined_recents'
  | 'writable_combined_shared'
  | 'search'

export type WallsFilter =
  | 'all'
  | 'made'
  | 'liked'
  | 'recents'
  | 'shared'
  | 'contributed'
  | 'network'
  | 'archived'
  | 'trashed'
  | 'writableShared'
  | 'writableLiked'
  | 'writableNetwork'
  | 'writableRecents'
  | 'portfolio'
  | 'private_walls'
  | 'writable_all'
  | 'combined_shared_user'
  | 'combined_shared_library'
  | 'profile'
  | 'templates'
  | CrossLibraryCollectionFilter

export type WallSortAttribute = 'name' | 'date'
export type WallSortDirection = 'asc' | 'desc'

interface FolderCollectionKey {
  typeKey: 'folderId'
  indexKey: FolderId
}
interface FilterCollectionKey {
  typeKey: 'filter'
  indexKey: WallsFilter
}

interface SearchCollectionKey {
  typeKey: 'search'
  indexKey: string
}

interface UserGroupFolderCollectionKey {
  typeKey: CollectionKeyTypes.GroupFolderId
  indexKey: UserGroupId
}

export type CollectionKey =
  | FolderCollectionKey
  | FilterCollectionKey
  | SearchCollectionKey
  | UserGroupFolderCollectionKey

export enum CollectionKeyTypes {
  Filter = 'filter',
  FolderId = 'folderId',
  GroupFolderId = 'groupFolderId',
  Search = 'search',
}

export interface CollectionsTree<T> {
  filter: Partial<Record<WallsFilter, T>>
  folderId: Record<FolderId, T>
  groupFolderId: Record<UserGroupId, T>
  search: Record<string, T>
}
export type CollectionsSubtree<T> =
  | Partial<Record<WallsFilter, T>>
  | Record<FolderId, T>
  | Record<UserGroupId, T>
  | Record<string, T>
